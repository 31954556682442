/* ========== Graph class ========== */
/**
 * Graph class.
 */
module.exports = class Graph { // eslint-disable-line no-unused-vars
    /**
     * Construct graph class.
     *
     * @param {String} id - Id of parent div.
     * @param {String} type - Graph type, tacho or fuel.
     * @param {Boolean} isMobile - Is Device a Mobile
     */
    constructor(id, type, isMobile) {
        // Select main element.
        this.element = document.getElementById(id);

        // Return if element is not present.
        if (!this.element) {
            return;
        }

        // Graph types have there own min-, max tilts
        // and backgrounds. Display error if graph type
        // is unknown.
        if (type === 'risk') {
            this.element.className = (isMobile) ?
                'tachoGraph mobileTachoGraph' :
                'tachoGraph';
            this.bg = '/images/Risiko_png.png';
            // this.minTilt = -49;
            // this.maxTilt = 228;
            this.minTilt = 0;
            this.maxTilt = 273;
        } else if (type === 'revenue') {
            this.element.className = (isMobile) ?
                'tachoGraph mobileTachoGraph' :
                'tachoGraph';
            this.bg = './images/Umsatz_png.png';
            this.minTilt = 0;
            this.maxTilt = 273;
        } else if (type === 'points') {
            this.element.className = (isMobile) ?
                'fuelGraph mobileFuelGraph' :
                'fuelGraph';
            this.bg = './images/Mitte_Neu.png';
            this.minTilt = -23;
            this.maxTilt = 23;
        } else {
            console.error(
                `Graph error: Graph type of id ` +
                `${this.element.id} not known. ` +
                `Known types: tacho, fuel.`,
            );
        }
    }

    /**
     * Show graph by assigning background image and
     * calculating gauge tilt.
     *
     * @param {Number} [value1=false] - First graph value.
     * @param {Number} [value2=false] - Secound graph value.
     */
    show(value1 = false, value2 = false) {
        // Return if element is not present.
        if (!this.element) {
            console.error('Chart element not found.');
            return;
        }

        // Helper var to determin if graph should be shown.
        let show = true;

        // Get data attributes of HTML element if value
        // is not present. Show error and halt execution
        // if values are missing.
        if (value1 === false && this.element.dataset.max) {
            value1 = this.element.dataset.max;
        } else if (value1 === false) {
            show = false;

            console.error(
                `Graph error: max of id: ` +
                `${this.element.id} not defined.`,
            );
        }

        // Get data attributes of HTML element if value
        // is not present. Show error and halt execution
        // if values are missing.
        if (value2 === false && this.element.dataset.value) {
            value2 = this.element.dataset.value;
        } else if (value2 === false) {
            show = false;

            console.error(
                `Graph error: value of id: ` +
                `${this.element.id} not defined.`,
            );
        }

        let maxValue = 0;
        let value = 0;

        // Check which value is max.
        if (value1 > value2) {
            maxValue = value1;
            value = value2;
        } else {
            maxValue = value2;
            value = value1;
        }

        if (show) {
            // Create Elements inside parent div. Only
            // if elements do not exist already.
            if (!this.gauge) {
                this.createElements();
                this.gauge = this.element.querySelector(`.gauge`);
            }

            // Only assign background and calculate gauge
            // tilt if background is given.
            if (this.bg) {
                this.element.style.backgroundImage = `url(${this.bg})`;
                this.element.style.backgroundSize = `contain`;
                this.element.style.backgroundRepeat = `no-repeat`;
                this.calculateTilt(maxValue, value);
            }
        }
    }

    /**
     * Calculate gauge tilt.
     *
     * @param {Number} maxValue - Highest value.
     * @param {Number} value - Reached Value.
     */
    calculateTilt(maxValue, value) {
        // Magic calculations.
        const percentTilt = (maxValue - value) / maxValue * 100;
        const tiltSteps = this.maxTilt - this.minTilt;
        const showTilt = (tiltSteps - tiltSteps * percentTilt / 100) +
            this.minTilt;

        // Display gauge tilt. Timeout to guarantee
        // correct transition.
        setTimeout(() => {
            this.gauge.style.transition = `transform 2s ease-out`;
            this.gauge.style.transform = `rotate(${showTilt + 5}deg)`;

            setTimeout(() => {
                this.gauge.style.transform = `rotate(${showTilt}deg)`;
            }, 2500);
        }, 200);
    }

    /**
     * Create elements inside parent div.
     */
    createElements() {
        // Define elements.
        const gauge = document.createElement('div');

        // Assign class names.
        gauge.className = 'gauge';

        // Append elements.
        this.element.appendChild(gauge);
    }
};
