<!--
    Displays Heading with hr
-->
<template>
    <div>
        <b-row class="text-center mb-4">
            <b-col
                v-if="!isMobile"
                class="d-table survey-spacer"
                cols="2"
            >
                <div class="d-table-cell sameHeight">
                    <hr class="border-dark">
                </div>
            </b-col>


            <b-col
                cols="8"
                class="headline d-table"
            >
                <div class="d-table-cell sameHeight">
                    {{ title }}
                </div>
            </b-col>

            <b-col
                v-if="!isMobile"
                class="d-table survey-spacer"
                cols="2"
            >
                <div class="d-table-cell sameHeight">
                    <hr class="border-dark">
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            required: true,
            type: String,
        },
    },

    computed: {
        isMobile() {
            return this.$store.state.mobile;
        },
    },
};
</script>

<style lang="scss" scoped>
.sameHeight {
    height: 55px;
    vertical-align: middle;
}

.headline {
    font-size: 32px;
    font-weight: 600;
}

@media only screen and (max-width: 1700px) {
    .headline {
        max-width: 100% !important;
        flex: 0 0 100%;
    }

    .survey-spacer {
        display: none !important;
    }
}

</style>
