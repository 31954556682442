/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
window.Vue = require('vue');

require('./bootstrap');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component />
 */
const files = require.context('./', true, /\.vue$/i);
files.keys().map(
    (key) => Vue.component(
        key.split('/').pop().split('.')[0], files(key).default,
    ),
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
window.Chart = require('chart.js');

window.Graph = require('./supergraph/graph');
import './supergraph/graph.css';

import Vuelidate from 'vuelidate';
import {store} from './store';

Vue.component('result', {
    props: {
        id: {
            required: true,
            type: String,
            default() {
                return 0;
            },
        },
    },
    template: '<ExtraResults :id="Number(id)" />',
});
Vue.use(require('bootstrap-vue'));
Vue.use(Vuelidate);

new Vue({
    el: '#app',
    store,
});
