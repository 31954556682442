import { render, staticRenderFns } from "./Begin.vue?vue&type=template&id=7b6c12e7&scoped=true&"
var script = {}
import style0 from "./Begin.vue?vue&type=style&index=0&id=7b6c12e7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b6c12e7",
  null
  
)

export default component.exports