<template>
    <div
        :class="[
            headingPosition < 0
                ? 'fixed-top floatNavigation'
                : 'listNavigation'
        ]"
    >
        <div
            v-for="(area, i) in areas"
            :key="area.id"
            class="area"
        >
            <p
                class="areaLink"
            >
                <!-- @click="changeArea(i)" -->
                {{ area.name }}
            </p>

            <div
                v-if="i === activeArea"
                id="navigationStageList"
            >
                <p
                    v-for="(stage, ind) in area.stages"
                    :id="`navigationStage-${stage.id}`"
                    :key="stage.id"
                    :href="`#stage-${stage.id}`"
                    :class="[
                        stageIndex === ind
                            ? 'active'
                            : '',
                        'stage mb-0'
                    ]"
                >
                    <!-- @click="changeStage(ind)" -->
                    {{ stage.name }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        index: {
            required: true,
            type: Number,
            default: 0,
        },

        areas: {
            required: true,
            type: Array,
            default() {
                return [];
            },
        },

        activeArea: {
            required: true,
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            headingPosition: document
                .getElementById('mainHeading')
                .getBoundingClientRect()
                .bottom,
        };
    },

    computed: {
        stageIndex() {
            return this.$store.state.stageIndex;
        },
    },

    mounted() {
        window.addEventListener(
            'scroll',
            this.updateScrollPosition,
        );
    },

    destroy() {
        window.removeEventListener(
            'scroll',
            this.updateScrollPosition,
        );
    },

    methods: {
        /**
         * Change area by changing store index.
         *
         * @param {Number} index - Index of area to change to.
         */
        changeArea(index) {
            // Increment index.
            this.$store.commit('setIndex', index);
            this.$store.commit('setStageIndex', 0);
        },

        /**
         * Change stage by changing store index.
         *
         * @param {Number} index - Index of stage to change to.
         */
        changeStage(index) {
            // Increment index.
            this.$store.commit('setStageIndex', index);
        },

        /**
         * Update scroll position variables.
         */
        updateScrollPosition() {
            this.headingPosition = document
                .getElementById('mainHeading')
                .getBoundingClientRect()
                .bottom;
        },
    },
};
</script>

<style lang="scss" scoped>
#navigationStageList {
    p {
        animation: staggeredList 500ms;
        animation-fill-mode: both;

        @for $i from 1 to 10 {
            &:nth-child(#{$i}) {
                animation-delay: 100ms * $i;
            }
        }
    }
}

@keyframes staggeredList {
    0% {
        opacity: 0;
        transform: scale(0.6) translateY(-8px);
    }
    100% {
        opacity: 1;
    }
}

.listNavigation {
    padding-left: 30px;
    padding-right: 30px;
    word-break: break-word;
}

.floatNavigation {
    padding-top: 60px;
    padding-left: 78px;
    max-width: inherit;
    word-break: break-word;
}

.areaLink {
    margin: 0;
    color: #595959;
}

.area {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 300;

    & .stage {
        padding-left: 20px;
        padding-bottom: 10px;
        display: block;
        font-size: 18px;
        color: #595959;
        font-weight: 300;
    }

    & .active {
        color: #153f93;
        font-weight: 600;
    }
}
</style>
