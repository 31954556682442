<!--
    Main Component
    Holds Survey steps and manage go through
-->
<template>
    <div class="container-fluid">
        <h1
            id="mainHeading"
            class="mainHeading"
        >
            Entdecken Sie Ihr Digitalisierungspo&shy;tential!
        </h1>

        <b-row class="content pb-5">
            <b-col
                v-if="!isMobile"
                cols="2"
                class="sideContent px-5"
            >
                <Navigation
                    v-if="steps[2] && areas.length"
                    :index="index"
                    :areas="areas"
                    :active-area="index"
                />
            </b-col>

            <b-col :class="{mobileContent: isMobile, mainContent: !isMobile}">
                <Begin
                    v-if="steps[0]"
                    class="survey"
                    @startsurvey="nextStep(0)"
                />

                <SelectPositionAndType
                    v-if="steps[1]"
                    class="survey"
                    :show="show"
                    @positionAndTypeSelected="
                        nextStep(1),
                        getAreas()
                    "
                />

                <Survey
                    v-if="steps[2] && areas.length"
                    :area="areas[index]"
                    :area-result-data="result[
                        areas[index].name.toLowerCase()
                    ]"
                    @nextArea="nextArea()"
                    @previousArea="previousArea()"
                />

                <AdditionalQuestion
                    v-if="steps[3]"
                    class="survey"
                    @startQuestions="nextStep(3)"
                />

                <FinalResult
                    v-if="steps[4]"
                    class="survey"
                    :areas="areas"
                />
            </b-col>

            <b-col
                v-if="!isMobile"
                cols="2"
                class="sideContent px-5"
            >
                <ProgressChart
                    v-if="steps[2] && areas.length"
                    :areas="areas"
                    :result="answeredQuestions"
                />
            </b-col>
        </b-row>
        <CookieAndAnalytics />
    </div>
</template>

<script>
/**
 * include Module for random unique key generation
 */
import uuid from 'uuid';

export default {

    props: {
        show: {
            required: true,
            type: String,
            default() {
                return '';
            },
        },

        skipstart: {
            required: true,
            type: String,
            default() {
                return '';
            },
        },
    },

    data() {
        return {
            areas: [],
        };
    },

    computed: {
        answeredQuestions() {
            return this.$store.state.answeredQuestions;
        },

        result() {
            return this.$store.state.result;
        },

        steps() {
            return this.$store.state.steps;
        },

        index() {
            return this.$store.state.index;
        },

        type() {
            return this.$store.state.type;
        },

        position() {
            return this.$store.state.position;
        },

        isMobile() {
            return this.$store.state.mobile;
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.checkForSmallWidth);
    },

    mounted() {
        if (this.skipstart) {
            this.nextStep(0);
        }

        this.$nextTick(() => {
            window.addEventListener('resize', this.checkForSmallWidth);
        });

        if (this.checkIfMobile()) {
            this.$store.commit('setMobile', true);
        } else {
            this.$store.commit('setMobile', false);
        }

        if (this.$store.state.key === null) {
            this.$store.commit('setKey', uuid.v4());
        }
    },

    methods: {
        /**
         * Incerement store index to load next stage.
         */
        nextArea() {
            if (this.index === this.areas.length - 1) {
                this.nextStep(2);
            } else {
                this.$store.commit('setIndex', this.index + 1);
            }
        },

        /**
         * Decrement store index to load previous stage.
         */
        previousArea() {
            if (this.index - 1 >= 0) {
                this.$store.commit('setIndex', this.index - 1);
            }
        },

        /**
         * Increase step, affect display of components and progressbar step
         * @param {Integer} current current step
         */
        nextStep(current) {
            const steps = [...this.steps];
            const lastSteps = [false, false];

            steps.splice(current, 1, false);
            steps.splice(current + 1, steps.length - current, true);

            this.$store.commit('setSteps', steps);

            if (current === 3) {
                lastSteps[0] = true;
                this.$store.commit('setLastSteps', lastSteps);
            } else if (current === 4) {
                lastSteps[0] = true;
                lastSteps[1] = true;
                this.$store.commit('setLastSteps', lastSteps);
            }
        },

        /**
         * Set step to given value and update template and progressbar
         * @param {Integer} step step to reset to
         */
        resetStepTo(step) {
            this.steps.splice(0, this.steps.length);

            for (let i = 0; i < step + 1; i++) {
                this.steps.push(false);
            }

            this.nextStep(step);
        },

        /**
         * Get Areas when type and position are set
         */
        getAreas() {
            axios.post('ajax/getAreas', {
                type_id: this.type,
                position_id: this.position,
            }).then((response) => {
                this.areas = response.data;

                /**
                 * Get names and mutate areaNamesAndPoints
                 */
                const names = response.data.map((area) => {
                    return area.name;
                });
                this.$store.commit('setAreaNames', names);
            });
        },

        /**
         * Check if the screenwidth is wide enough to
         * properly display the content
         */
        checkForSmallWidth() {
            const width = window.innerWidth;

            if (width < 1700) {
                this.$store.commit('setMobile', true);
            } else {
                if (!this.checkIfMobile()) {
                    this.$store.commit('setMobile', false);
                }
            }
        },

        /**
         * Check if device is mobile
         * @return {Boolean} True if the device is mobile
         */
        checkIfMobile() {
            const connection = navigator.connection ||
                    navigator.mozConnection ||
                    navigator.webkitConnection;

            if (
                /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i // eslint-disable-line max-len
                    .test(navigator.userAgent) ||
                    ('screen' in window && window.screen.width < 1000) ||
                    (connection && connection.type === 'cellular')
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    background: #ffffff;
    min-height: 60vh;
}

.sideContent {
    margin-top: 60px;
    margin-bottom: 60px;
    flex: 0 0 22%;
    max-width: 22%;
}

.mainContent {
    flex: 0 0 56%;
    max-width: 56%;
}

.mobileContent {
    flex: 0 0 100%!important;
    max-width: 100%!important;
}

.survey {
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 32px;
    background-color: #efefef;
}

.mainHeading {
    color: white;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 70px;
    margin: 0;
    font-size: 40px;
}
</style>
