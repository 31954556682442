<template>
    <div>
        <strong class="text-success">
            Sie werden in {{ remainingDelay }} Sekunden weitergeleitet.
        </strong>
        <p>
            <!-- eslint-disable-next-line max-len -->
            Wenn Sie Probleme mit der automatischen Weiterleitung habe, klicken sie auf den nachfolgenden Link und Sie werden zur TÜV-Seite weitergeleitet. <a :href="url">{{ url }}</a>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            required: true,
            type: String,
            default() {
                return '';
            },
        },
        delay: {
            required: true,
            type: Number,
            default() {
                return 5000;
            },
        },
    },

    data() {
        return {
            remainingDelay: this.delay / 1000,
        };
    },

    mounted() {
        setInterval(() => {
            if (this.remainingDelay > 0) {
                this.remainingDelay--;
            }
        }, 1000);

        setTimeout(() => {
            window.location.href = this.url;
        }, this.delay);
    },
};
</script>
