/**
 * Get the requested route
 * @param {String} name name of the Route
 * @param  {...any} args ids, slugs or other parameters
 * @return {Function} An async function with the route as String
 */
export async function getRoute(name, ...args) {
    return axios.post('/ajax/routes', {
        name: name,
        args: args,
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        console.error(error);
    });
}

/**
 * Checks if a prop exists in an object
 * @param {Object} object Object to check
 * @param {String} prop Prop to check if exists
 * @return {Boolean} True or False
 */
export function objectHasProp(object, prop) {
    return Object.prototype.hasOwnProperty.call(object, prop);
}
