<!-- eslint-disable vue/attribute-hyphenation -->
<template>
    <div :class="`${(showBanner) ? 'go-digital-banner-background' : ''}`">
        <vue-cookie-accept-decline
            :elementId="'go-digital-banner'"
            :debug="false"
            :position="'top'"
            :type="'bar'"
            :disableDecline="false"
            :transitionName="'slideFromBottom'"
            :showPostponeButton="false"
            @status="cookieStatus"
            @clicked-decline="cookieClickedAccept"
            @clicked-accept="showBanner = false"
        >
            <div slot="message">
                <h3>COOKIE-EINSTELLUNG</h3>
                <p class="mb-0">
                    Bitte treffen Sie eine Auswahl.
                </p>
                <p class="bm">
                    Weitere Informationen unter: <a
                        href="/policies/privacy"
                        target="_blank"
                    >Infos zum Datenschutz</a>.
                </p>
            </div>

            <div slot="acceptContent">
                Notwendige Cookies zulassen
            </div>

            <div slot="declineContent">
                Alle Cookies zulassen
            </div>
        </vue-cookie-accept-decline>
    </div>
</template>

<script>
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

export default {
    components: {
        VueCookieAcceptDecline,
    },

    data() {
        return {
            showBanner: localStorage.getItem(
                'vue-cookie-accept-decline-go-digital-banner',
            ) === null,
        };
    },

    methods: {
        cookieClickedAccept() {
            this.loadScript(() => this.executeGoogleScript());
            this.showBanner = false;
        },

        cookieStatus(status) {
            if (status === 'decline') {
                this.loadScript(() => this.executeGoogleScript());
            }
        },

        /**
         * Load the google tag manager script
         * @param {Function} onLoad The function called on load
         */
        loadScript(onLoad) {
            const script = document.createElement('script');
            script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-108369194-5';
            script.async = true;
            script.onload = () => onLoad();
            document.head.appendChild(script);
        },

        executeGoogleScript() {
            window.dataLayer = window.dataLayer || [];

            /**
             * Googles gtag function
             */
            function gtag() {
                // eslint-disable-next-line prefer-rest-params, no-undef
                dataLayer.push(arguments);
            }

            gtag('js', new Date());
            gtag('config', 'UA-108369194-5');
            // eslint-disable-next-line no-undef
            ga('set', 'anonymizeIp', true);
        },
    },
};
</script>

<style lang="scss">
    #go-digital-banner {
        width: 475px !important;
        left: calc(100vw / 2 - 237.5px) !important;
        right: calc(100vw / 2 - 237.5px) !important;
        padding: 50px;
        position: fixed;
        top: 0;
        background-color: white;
        z-index: 910;
        color: #606569;

        div {
            flex: 1 0 calc(100% - 30px) !important;
            text-align: center;
        }

        a {
            color: #606569;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }

        h3 {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    .bm {
        margin-bottom: 15px
    }

    .cookie__bar__content {
        overflow: visible !important;
    }

    .cookie__bar__buttons {
        button {
            width: calc(100% - 30px);
            background: white !important;
            color: #606569 !important;
            padding: 5px 10px;
            margin: 15px;

            &:first-child {
                border: 1px solid lightgrey !important;

                &:hover {
                    background-color: #071827 !important;
                    color: white !important;
                }
            }

            &:last-child {
                border: none;
                color: #606569;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        #go-digital-banner {
            width: 100% !important;
            left: 0 !important;
            right: 0 !important;
        }
    }

    .go-digital-banner-background {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 900;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);
    }
</style>
