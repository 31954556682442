<!--
    Contact form for result page
-->
<template>
    <div>
        <div class="text-center">
            <h4 class="headline">
                Detailanalyse anfragen
            </h4>

            <p class="text my-5">
                Sie möchten eine Detailanalyse Ihrer Firma bzw.
                Sie wollen wissen, wie Sie sich verbessern können?
                Kontaktieren Sie uns über das untenstehende Formular.
                Wir melden uns zeitnah bei Ihnen.
            </p>
        </div>

        <div class="container">
            <form
                class="contact-form"
                @submit.prevent="submit"
            >
                <div
                    class="form-group input-group"
                    :class="{ 'form-group--error': $v.name.$error }"
                >
                    <div class="input-group-prepend quiz-input">
                        <span
                            id="basic-addon1"
                            class="input-group-text"
                        >
                            Vor-/Nachname*
                        </span>
                    </div>

                    <input
                        id="name"
                        v-model.trim="$v.name.$model"
                        class="form-control quiz-input"
                    >

                    <div
                        v-if="!$v.name.required"
                        class="error"
                    >
                        Bitte geben Sie Ihren Namen an!
                    </div>
                </div>

                <div
                    class="form-group input-group"
                    :class="{ 'form-group--error': $v.company.$error }"
                >
                    <div class="input-group-prepend quiz-input">
                        <span
                            id="basic-addon1"
                            class="input-group-text"
                        >
                            Firma*
                        </span>
                    </div>

                    <input
                        id="company"
                        v-model.trim="$v.company.$model"
                        class="form-control quiz-input"
                    >

                    <div
                        v-if="!$v.company.required"
                        class="error"
                    >
                        Bitte geben Sie Ihre Firma an!
                    </div>
                </div>

                <div
                    class="form-group input-group"
                    :class="{ 'form-group--error': $v.mail.$error }"
                >
                    <div class="input-group-prepend quiz-input">
                        <span
                            id="basic-addon1"
                            class="input-group-text"
                        >
                            E-Mail*
                        </span>
                    </div>

                    <input
                        id="mail"
                        v-model.trim="$v.mail.$model"
                        type="email"
                        class="form-control quiz-input"
                    >

                    <div
                        v-if="!$v.mail.required"
                        class="error float-left"
                    >
                        Bitte geben Sie Ihre E-Mail an!
                    </div>

                    <div
                        v-if="!$v.mail.email"
                        class="error float-left"
                    >
                        Ihre Eingabe ist keine Valide E-Mail!
                    </div>
                </div>

                <div
                    class="form-group input-group"
                >
                    <div class="input-group-prepend quiz-input">
                        <span
                            id="basic-addon1"
                            class="input-group-text"
                        >
                            Telefon
                        </span>
                    </div>

                    <input
                        id="phone"
                        class="form-control quiz-input"
                    >
                </div>

                <b-row
                    :class="{
                        invalid: submitStatus === 'ERROR'
                            && $v.terms.$invalid
                    }"
                >
                    <b-col cols="1">
                        <input
                            id="terms"
                            v-model="terms"
                            class="quizquestion btn-block mt-1"
                            type="checkbox"
                            name="terms"
                            value="0"
                            @change="$v.terms.$touch()"
                        >

                        <label
                            class="checkboxlabel m-0"
                            for="terms"
                        />
                    </b-col>

                    <b-col
                        cols="11"
                        class="text-left"
                        @click="terms = !terms"
                    >
                        <p>
                            Ich stimme zu, dass meine Angaben aus dem
                            Kontaktformular zur Beantwortung meiner
                            Anfrage erhoben und verarbeitet werden.
                            Die Daten werden nach abgeschlossener
                            Bearbeitung Ihrer Anfrage gelöscht.*
                        </p>

                        <p>
                            Sie können Ihre Einwilligung jederzeit
                            für die Zukunft per E-Mail an
                            <a href="mailto:info@1a-digital.com">
                                info@1a-digital.com
                            </a>
                            widerrufen. Detaillierte Informationen zum
                            Umgang mit Nutzerdaten finden Sie in unserer
                            <a
                                href="https://1a-digital.com/datenschutz/"
                                target="_blank"
                            >Datenschutzerklärung</a>.
                        </p>
                    </b-col>
                </b-row>

                <p class="small">
                    *Pflichtfeld
                </p>

                <div class="text-center">
                    <button
                        :class="`btn animated my-4${(
                            submitStatus === 'PENDING' ||
                            name == '' ||
                            company == '' ||
                            mail == '' ||
                            !terms
                        ) ? ' disabled' : ''}`"
                        type="submit"
                        :disabled="
                            submitStatus === 'PENDING' ||
                                name == '' ||
                                company == '' ||
                                mail == '' ||
                                !terms
                        "
                    >
                        Senden
                    </button>
                </div>

                <p
                    v-if="submitStatus === 'OK'"
                    class="typo__p mail_error_label"
                >
                    Vielen Dank für Ihre Anfrage
                </p>

                <p
                    v-if="submitStatus === 'MAILERROR'"
                    class="typo__p mail_error_label"
                >
                    Die E-mail konnte nicht korrekt versendet werden,
                    versuchen Sie es erneut.
                </p>

                <p
                    v-if="submitStatus === 'PENDING'"
                    class="typo__p mail_error_label"
                >
                    Wird gesendet...
                </p>

                <Redirect
                    v-if="submitStatus === 'OK'"
                    url="https://www.tuev-nord.de/de/unternehmen/verkehr/autohaus-und-werkstatt/effizienz-steigern/digitalisierungsberatung/"
                    :delay="5000"
                />
            </form>
        </div>
    </div>
</template>

<script>
import {required, sameAs, email} from 'vuelidate/lib/validators';

export default {
    props: {
        points: {
            required: true,
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: Object|null,
            default() {
                return {};
            },
        },
        areaPercentages: {
            required: true,
            type: Array,
            default() {
                return {};
            },
        },
        refference: {
            required: true,
            type: String,
            default() {
                return '';
            },
        },
    },

    data() {
        return {
            name: '',
            company: '',
            mail: '',
            phone: '',
            terms: false,
            submitStatus: null,
        };
    },

    computed: {
        form() {
            return {
                id: this.$store.state.key,
                name: this.name,
                company: this.company,
                mail: this.mail,
                phone: this.phone,
                terms: this.terms,
                additional: this.additionalInfo,
            };
        },

        additionalInfo() {
            return this.$store.state.additional;
        },

        results() {
            return this.$store.state.result;
        },
    },
    /**
     * Setup form validation
     */
    validations: {
        name: {
            required,
        },
        company: {
            required,
        },
        mail: {
            required,
            email,
        },
        terms: {
            sameAs: sameAs(() => true),
        },
    },

    methods: {
        /**
         * Send form when valid
         */
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
            } else {
                this.submitStatus = 'PENDING';

                const data = {
                    id: this.$store.state.key,
                    uID: this.refference,
                    name: this.name,
                    company: this.company,
                    mail: this.mail,
                    phone: this.phone,

                    type: this.$store.state.type ?
                        'Service' :
                        'Service mit Vertrieb',
                    goal: this.$store.state.position === 1 ?
                        'Basic' :
                        this.$store.state.position === 2 ?
                            'Standard' :
                            this.$store.state.position === 3 ?
                                'Fortgeschritten' :
                                '30-Minuten Test',

                    employees: this.additionalInfo[0].answer,
                    stateFunding: this.additionalInfo[1].answer,
                    yearBalance: this.additionalInfo[2].answer,

                    revenue: Math.round(
                        100 /
                        this.points.revenue.max * this.points.revenue.value,
                    ),
                    risk: Math.round(
                        100 / this.points.risk.max * this.points.risk.value,
                    ),
                    pointsReached: this.points.points.value,
                    pointsMax: this.points.points.max,

                    results: this.results,
                    areaPercentages: this.areaPercentages,

                    terms: this.terms,
                };

                axios.post('ajax/sendMail', data)
                    .then((response) => {
                        this.submitStatus = 'OK';
                    }).catch((error) => {
                        this.submitStatus = 'MAILERROR';
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 720px;
}

.headline {
    font-size: 28px;
    font-weight: 600;
}


.text {
    font-size: 22px;
    font-weight: 300;
}

.error {
    color: red;
    font-size: 20px;
}

.quiz-input {
    height: calc(1.6em + 1.25rem + 2px);
    font-size: 20px;
    border-radius: 0;
}

.mail_error_label {
    font-size: large;
    font-weight: bold;
}
</style>
