<!--
    Display the tool name and hold the tool specific questions
-->
<template>
    <div :id="`tool-${tool.id}`">
        <div
            id="questionList"
            class="container"
        >
            <Question
                v-for="question in tool.questions"
                :id="`question-${question.id}`"
                :key="question.id"
                :question="question"
                :disabled="isSkipped(question)"
                :question-result-data="toolResultData[question.id]"
                @answered="addToolToResult($event, tool.name)"
                @removeQuestion="$emit(
                    'removeQuestion',
                    {
                        questionID: $event.questionID,
                        toolName: tool.name
                    }
                );"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tool: {
            required: true,
            type: Object,
            default() {
                return {
                    id: '',
                    name: '',
                    questions: [],
                };
            },
        },

        toolResultData: {
            required: false,
            type: Object,
            default() {
                return {};
            },
        },
    },

    methods: {
        /**
         * Check if question is disabled
         * @param {Object} question Displayed question
         * @return {Boolean} true if question is disabled, else returns false
         */
        isSkipped(question) {
            if (question.skippedby) {
                if (!this.toolResultData[question.skippedby]) {
                    return true;
                }
                return this.toolResultData[question.skippedby].value === 0;
            }
            return false;
        },

        /**
         * Add tool id to question result.
         *
         * @param {Object} resultData - Question data: id, value, type.
         * @param {String} toolName - Tool name of answered question.
         */
        addToolToResult(resultData, toolName) {
            const resultDataWithTool = {
                toolName: toolName,
                question: resultData,
            };

            this.$emit('answered', resultDataWithTool);
        },
    },
};
</script>

<style lang="scss" scoped>
#questionList {
    div {
        animation: staggeredList 1000ms;
        animation-fill-mode: both;

        @for $i from 1 to 20 {
            &:nth-child(#{$i}) {
                animation-delay: 200ms * $i;
            }
        }
    }
}

@keyframes staggeredList {
    0% {
        opacity: 0;
        transform: scale(0.6) translateY(-8px);
    }
    100% {
        opacity: 1;
    }
}
</style>
