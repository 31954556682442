import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        index: 0,
        stageIndex: 0,
        position: 0,
        type: 0,
        key: null,
        // areas: [],
        lastSteps: [false, false],
        steps: [true],
        additional: {},
        mobile: false,
        result: {},
        answeredQuestions: 0,
        areaNamesAndPoints: {
            names: [],
            points: [],
            pointsMax: [],
        },
        points: null,
    },

    /**
     * VueX Mutations
     */
    mutations: {
        setAdditional(state, data) {
            state.additional = data;
        },

        // setAreas(state, data) {
        //     state.areas = data;
        // },

        setIndex(state, data) {
            state.index = data;
        },

        setStageIndex(state, data) {
            state.stageIndex = data;
        },

        setPosition(state, data) {
            state.position = data;
        },

        setSteps(state, data) {
            state.steps = data;
        },

        setType(state, data) {
            state.type = data;
        },

        setLastSteps(state, data) {
            state.lastSteps = data;
        },

        setKey(state, data) {
            state.key = data;
        },

        setMobile(state, data) {
            state.mobile = data;
        },

        setAnsweredQuestions(state) {
            state.answeredQuestions++;
        },

        /**
         * Set result, store points, risk and revenue
         * @param {Object} state Stored state
         * @param {Object} data New points, risk and revenue
         */
        setResult(state, data) {
            const area = data.areaName.toLowerCase();
            const stage = data.stageName.toLowerCase();
            const tool = data.toolName.toLowerCase();

            // Handle creating result object.
            // If area does not exist create area, stage and tool.
            if (!Object.prototype.hasOwnProperty.call(
                state.result,
                area,
            )) {
                Vue.set(
                    state.result,
                    area,
                    {[stage]: {[tool]: {}}},
                );
            // If stage does not exist create stage and tool.
            } else if (!Object.prototype.hasOwnProperty.call(
                state.result[area],
                stage,
            )) {
                Vue.set(
                    state.result[area],
                    stage,
                    {[tool]: {}},
                );
            // If tool does not exist create tool.
            } else if (!Object.prototype.hasOwnProperty.call(
                state.result[area][stage],
                tool,
            )) {
                Vue.set(
                    state.result[area][stage],
                    tool,
                    {},
                );
            }

            /**
             * Concat new question if question is not set
             */
            Vue.set(
                state.result[area][stage][tool],
                [data.question.id],
                data.question,
            );
        },

        /**
         * Remove question from result
         * @param {Object} state VueX state
         * @param {Object} data Question id
         */
        removeQuestion(state, data) {
            const area = data.areaName.toLowerCase();
            const stage = data.stageName.toLowerCase();
            const tool = data.toolName.toLowerCase();

            // Find matching Object and delete it.
            Vue.delete(
                state.result[area][stage][tool],
                data.questionID,
            );
        },

        /**
         * Calculate result points based on
         * result object.
         *
         * @param {Object} state - Stored state.
         */
        getCalculatedPoints(state) {
            // Reset result object.
            const result = {
                points: {
                    value: 0,
                    max: 0,
                },
                risk: {
                    value: 0,
                    max: 0,
                },
                revenue: {
                    value: 0,
                    max: 0,
                },
            };

            /**
             * Store questions for calculation
             * @type {Array}
             */
            let questions = [];
            let areaPoints = null;

            // First we loop through every area we have.
            for (const area in state.result) {
                if (Object.prototype.hasOwnProperty.call(
                    state.result,
                    area,
                )) {
                    areaPoints = {
                        value: 0,
                        max: 0,
                    };

                    // state.areaNamesAndPoints.names = [
                    //     ...state.areaNamesAndPoints.names,
                    //     area,
                    // ];

                    // Next we loop through stages found in area object.
                    for (const stage in state.result[area]) {
                        if (Object.prototype.hasOwnProperty.call(
                            state.result[area],
                            stage,
                        )) {
                            // Then we loop through tools of given stage stage.
                            for (const tool in state.result[area][stage]) {
                                if (Object.prototype.hasOwnProperty.call(
                                    state.result[area][stage],
                                    tool,
                                )) {
                                    // Add questions
                                    const toolQuestions = state
                                        .result[area][stage][tool];

                                    questions = questions.concat(
                                        toolQuestions,
                                    );

                                    for (const question in toolQuestions) {
                                        if (Object.prototype.hasOwnProperty
                                            .call(
                                                state.areaNamesAndPoints,
                                                'points',
                                            )
                                        ) {
                                            // eslint-disable-next-line max-len
                                            areaPoints.value += toolQuestions[question].value;
                                            areaPoints.max += 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    state.areaNamesAndPoints.points = [
                        ...state.areaNamesAndPoints.points,
                        areaPoints.value,
                    ];
                    state.areaNamesAndPoints.pointsMax = [
                        ...state.areaNamesAndPoints.pointsMax,
                        areaPoints.max,
                    ];
                }
            }

            /**
             * Loop through questions and calculate points
             */
            questions.map((question) => {
                for (const questionKey in question) {
                    if (Object.prototype.hasOwnProperty.call(
                        question,
                        questionKey,
                    )) {
                        const data = question[questionKey];
                        result.points.max++;
                        result.points.value += data.value;

                        switch (data.type) {
                        // risk
                        case 2:
                            result.risk.max++;
                            result.risk.value += data.value;
                            break;
                        // revenue
                        case 3:
                            result.revenue.max++;
                            result.revenue.value += data.value;
                            break;
                        }
                    }
                }
            });

            state.points = result;
        },

        /**
         * Add the area names to areaNamesAndPoints
         * Names are set separately to avoid all lower case names
         * @param {Object} state Stored state
         * @param {Array} data Area names
         */
        setAreaNames(state, data) {
            data.map((name) => {
                state.areaNamesAndPoints.names = [
                    ...state.areaNamesAndPoints.names,
                    name,
                ];
            });
        },
    },
});
