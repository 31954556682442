<template>
    <div
        :class="'listNavigation'"
    >
        <div
            v-for="(area, i) in areas"
            :key="area.id"
            :class="`area${(i === index) ? ' active': ''}`"
        >
            <p
                class="areaLink"
                @click="changeArea(i)"
            >
                {{ area }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        index: {
            required: true,
            type: Number,
            default: 0,
        },

        areas: {
            required: true,
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        /**
         * Change area by emitting an event
         * @param {Number} index Index of area to change to
         */
        changeArea(index) {
            this.$emit('changeIndex', index);
        },
    },
};
</script>

<style lang="scss" scoped>
.listNavigation {
    padding-left: 30px;
    padding-right: 30px;
    word-break: break-word;
}

.areaLink {
    margin: 0;
    color: #595959;
    cursor: pointer;
}

.area {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 300;

    &.active {
        p {
            color: #153f93;
            font-weight: 600;
        }
    }
}
</style>
