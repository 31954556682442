/* eslint-disable */
/**
 * The additional questions
 * @type {Array}
 */
export const additionalQuestions = [
    {
        id: 1,
        text: 'Beschäftigen Sie weniger als 100 Mitarbeitende in Ihrem Unternehmen?',
        type: 'choice',
    },
    {
        id: 2,
        text: 'Haben Sie innerhalb der letzten drei Jahre weniger als 200.000€ staatliche Förderung erhalten?',
        type: 'choice',
    },
    {
        id: 3,
        text: 'Erwirtschaftet Ihr Unternehmen jährlich weniger als 20 Mio. € Umsatz bzw. weisen Sie weniger als 20 Mio. € Bilanzsumme aus?',
        type: 'choice',
    },
];
