var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":("stage-" + (_vm.stage.id))}},[_c('Heading',{attrs:{"title":_vm.stage.name}}),_vm._v(" "),_c('h4',{staticClass:"tool-headline mb-5 text-center"},[_vm._v("\n        "+_vm._s(_vm.stage.tools[_vm.toolIndex].name)+"\n    ")]),_vm._v(" "),_c('Tool',{attrs:{"id":("tool-" + (_vm.stage.tools[_vm.toolIndex].id)),"tool":_vm.stage.tools[_vm.toolIndex],"tool-result-data":_vm.stageResultData[
                _vm.stage.tools[_vm.toolIndex].name.toLowerCase()
            ]},on:{"answered":function($event){return _vm.addStageToResult($event, _vm.stage.name)},"removeQuestion":function($event){return _vm.$emit(
            'removeQuestion',
            {
                questionID: $event.questionID,
                toolName: $event.toolName,
                stageName: _vm.stage.name
            }
        );}}}),_vm._v(" "),_c('PrevAndNextButton',{attrs:{"show-previous":_vm.toolIndex !== 0,"enable-next":_vm.stageResultLength === _vm.stage
                .tools[_vm.toolIndex]
                .questions
                .length,"label-previous":'Zurück',"label-next":'Weiter'},on:{"previous":function($event){return _vm.previousTool()},"next":function($event){return _vm.nextTool()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }