<!--
    Display a single additional question
-->
<template>
    <div
        :id="`question-${index}`"
        class="mt-5"
    >
        <div class="row text-center mb-4">
            <div class="d-table col-2 go-spacer">
                <div class="d-table-cell sameHeight">
                    <hr class="border-dark">
                </div>
            </div>
            <div class="headline col-8 d-table">
                <div class="d-table-cell sameHeight">
                    {{ question.text }}
                </div>
            </div>
            <div class="d-table col-2 go-spacer">
                <div class="d-table-cell sameHeight">
                    <hr class="border-dark">
                </div>
            </div>
        </div>

        <b-row>
            <ul class="checkBoxList">
                <b-row>
                    <b-col>
                        <li class="checkBoxList leftBox">
                            <input
                                :id="`answer_yes${index}`"
                                v-model="answer"
                                class="quizquestion btn-block mt-1"
                                type="radio"
                                :name="`answer${index}`"
                                :value="1"
                                @change="doAnswer()"
                            >
                            <label
                                class="checkboxlabel"
                                :for="`answer_yes${index}`"
                            >
                                <p class="checkboxText">
                                    &nbsp; &nbsp;Ja
                                </p>
                            </label>
                        </li>
                    </b-col>

                    <b-col>
                        <li class="checkBoxList">
                            <input
                                :id="`answer_no${index}`"
                                v-model="answer"
                                class="quizquestion btn-block mt-1"
                                type="radio"
                                :name="`answer${index}`"
                                :value="0"
                                @change="doAnswer()"
                            >

                            <label
                                class="checkboxlabel"
                                :for="`answer_no${index}`"
                            >
                                <p class="checkboxText">
                                    &nbsp; &nbsp;Nein
                                </p>
                            </label>
                        </li>
                    </b-col>
                </b-row>
            </ul>
        </b-row>
    </div>

<!--

    <div>
        <div
            v-if="question.type === 'choice'"
            :id="`question-${index}`"
            class="mt-5"
        >
            <p
                class="text-center question-text m-0 segment-question-wrapper"
            >
                <span class="segment-question-text">
                    {{ question.text }}
                </span>
            </p>

            <b-row>
                <ul class="checkBoxList">
                    <b-row>
                        <b-col>
                            <li class="checkBoxList leftBox">
                                <input
                                    :id="`answer_yes${index}`"
                                    v-model="answer"
                                    class="quizquestion btn-block mt-1"
                                    type="radio"
                                    :name="`answer${index}`"
                                    :value="1"
                                    @change="doAnswer()"
                                >
                                <label
                                    class="checkboxlabel"
                                    :for="`answer_yes${index}`"
                                >
                                    <p class="checkboxText">
                                        &nbsp; &nbsp;Ja
                                    </p>
                                </label>
                            </li>
                        </b-col>

                        <b-col>
                            <li class="checkBoxList">
                                <input
                                    :id="`answer_no${index}`"
                                    v-model="answer"
                                    class="quizquestion btn-block mt-1"
                                    type="radio"
                                    :name="`answer${index}`"
                                    :value="0"
                                    @change="doAnswer()"
                                >

                                <label
                                    class="checkboxlabel"
                                    :for="`answer_no${index}`"
                                >
                                    <p class="checkboxText">
                                        &nbsp; &nbsp;Nein
                                    </p>
                                </label>
                            </li>
                        </b-col>
                    </b-row>
                </ul>
            </b-row>
        </div>

        <div
            v-if="question.type === 'number'"
            class="mb-3"
        >
            <p
                class="text-center question-text m-0"
            >
                {{ question.text }}
            </p>

            <b-row>
                <input
                    v-model.number="answer"
                    class="form-control quizinput"
                    type="number"
                    :min="1"
                    :max="5000"
                    @input="checkInput($event), doAnswer()"
                >
            </b-row>
        </div>
    </div> -->
</template>

<script>
export default {
    props: {
        question: {
            required: true,
            type: Object,
            default() {
                return {};
            },
        },
        index: {
            required: true,
            type: Number,
            default() {
                return 0;
            },
        },
    },

    data() {
        return {
            answer: null,
        };
    },

    methods: {
        /**
         * Emit answer to parent component
         */
        doAnswer() {
            this.$emit('answered', {
                index: this.index,
                value: this.answer,
            });
        },

        /**
         * Check if the input value is min 1 and max 5000
         * @param {Event} e Input event
         */
        checkInput(e) {
            if (e.target.value < 1) {
                this.answer = 1;
            } else if (e.target.value > 5000) {
                this.answer = 5000;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.question-text {
    font-size: 22px;
    font-weight: 300;
    transition: 0.2s all;
}

.quizinput {
    border: 2px solid #000000;
    border-radius: 0px;
    -webkit-appearance: none;
    margin: 30px auto;
    width: 250px;
    text-align: center;
    height: 55px;
    font-size: 22px;
    font-weight: 600;
    background-color: #efefef;
    color: #000000;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@media screen and (min-width:0\0) {
    .leftBox {
        left: -120px;
    }
}

.segment-question-wrapper {
    border-bottom: 1px solid #000;
    line-height: 0.1em;

    .segment-question-text {
        background: #efefef;
        padding: 0 10px;
    }
}

.sameHeight {
    height: 55px;
    vertical-align: middle;
}

.headline {
    font-size: 20px;
}

@media only screen and (max-width: 800px) {
    .headline {
        max-width: 100%;
    }

    .go-spacer {
        display: none !important;
    }
}
</style>
