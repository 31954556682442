
<template>
    <div class="container-fluid">
        <h1
            id="mainHeading"
            class="mainHeading"
        >
            Umfrageergebnisse
        </h1>

        <b-row class="content pb-5">
            <b-col
                cols="2"
                class="sideContent px-5"
            >
                <ShortendNavigation
                    :areas="areas"
                    :index="index"
                    @changeIndex="(value) => index = value"
                />
            </b-col>
            <b-col
                cols="10"
                class="mainContent px-5"
            >
                <QuestionTable
                    :area="points[index]"
                    :is-specific="isSpecific"
                    @search="(mail) => getSpecificResult(mail)"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {getRoute, objectHasProp} from '../../helpers';

export default {
    props: {
        id: {
            required: false,
            type: Number,
            default() {
                return 0;
            },
        },
    },

    data() {
        return {
            areas: [],
            points: [],
            index: 0,
            isSpecific: false,
        };
    },

    /**
     * Get the statisitc with average values
     */
    mounted() {
        if (!this.id) {
            getRoute('ajax.statistics.all').then((route) => {
                axios.post(route).then((response) => {
                    const data = response.data;
                    for (const key in data) {
                        if (objectHasProp(data, key)) {
                            this.areas = this.areas.concat(key);
                            this.points = this.points.concat(
                                this.getPoints(data, key),
                            );
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });
            });
        } else {
            this.getSpecificResult(this.id);
        }
    },

    methods: {
        /**
         * Extract the points from the given object
         * @param {Object} areas The areas wich contains
         * the points and questions
         * @param {String} key The prop to the object
         * @return {Array}
         */
        getPoints(areas, key) {
            let response = {};
            this.iterateObject(
                areas[key],
                (obj) => this.iterateObject(obj, (points) => {
                    response = Object.assign({}, response, {
                        [key]: (typeof response[key] !== 'undefined') ?
                            response[key].concat(points) : points,
                    });
                }),
            );

            return response;
        },

        /**
         * Iterator for objects
         * @param {Object} object
         * @param {Function} callback The function called on the accessed data
         */
        iterateObject(object, callback) {
            for (const prop in object) {
                if (objectHasProp(object, prop)) {
                    callback(object[prop]);
                }
            }
        },

        /**
         * Getter for a specific resultset
         * @param {Number|String} IDOrMail The id or the mail to get the result
         */
        getSpecificResult(IDOrMail) {
            this.areas = [];
            this.points = [];
            if (IDOrMail !== '') {
                this.isSpecific = true;
            } else {
                this.isSpecific = false;
            }

            getRoute('ajax.statistics.single').then((route) => {
                axios.post(route, {
                    IDOrMail: IDOrMail,
                }).then((response) => {
                    const data = response.data;
                    for (const key in data) {
                        if (objectHasProp(data, key)) {
                            this.areas = this.areas.concat(key);
                            this.points = this.points.concat(
                                this.getPoints(data, key),
                            );
                        }
                    }
                }).catch((error) => {
                    alert('Für die verwendete E-Mail gibt es keine Einträge!');
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    background: #ffffff;
    min-height: 60vh;
}

.sideContent {
    margin-top: 60px;
    margin-bottom: 60px;
    flex: 0 0 22%;
    max-width: 22%;
}

.mainContent {
    flex: 0 0 70%;
    max-width: 70%;
}

.mobileContent {
    flex: 0 0 100%!important;
    max-width: 100%!important;
}

.survey {
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 32px;
    background-color: #efefef;
}

.mainHeading {
    color: white;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 70px;
    margin: 0;
    font-size: 40px;
}
</style>
