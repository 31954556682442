<!--
    Display a Radar chart with chart.js
-->
<template>
    <canvas
        id="spiderChart"
        :width="width"
        :height="height"
    />
</template>

<script>
export default {
    props: {
        graphLabels: {
            required: true,
            type: Array,
            default() {
                return [];
            },
        },

        graphPercentages: {
            required: true,
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            width: 300,
            height: 300,
            spiderChart: null,
            ctx: null,
        };
    },

    mounted() {
        this.ctx = document
            .getElementById('spiderChart')
            .getContext('2d');

        setTimeout(() => {
            this.makeChart();
        }, 50);
    },

    methods: {
        /**
         * Chart.js function to render the radarys chart
         */
        makeChart() {
            this.spiderChart = new Chart(this.ctx, {
                type: 'radar',
                data: {
                    labels: this.graphLabels,
                    datasets: [
                        {
                            label: 'Ihre Punktzahl',
                            backgroundColor: 'rgba(21, 63, 147, 0.5)',
                            borderColor: 'rgba(21, 63, 147, 0.5)',
                            data: this.graphPercentages,
                        },
                    ],
                },

                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 5000,
                        easing: 'easeInOutQuad',
                    },

                    tooltips: {
                        // add Values to labels (workaround for
                        // a bug in chart.js)
                        callbacks: {
                            label: function(tooltipItem, data) {
                                const label = data
                                    .datasets[tooltipItem.datasetIndex]
                                    .label;
                                const value = data
                                    .datasets[tooltipItem.datasetIndex]
                                    .data[tooltipItem.index];

                                return `${label} : ${value}`;
                            },
                        },
                    },

                    scale: {
                        pointLabels: {
                            fontSize: 15,
                            fontColor: '#868e96',
                        },
                        ticks: {
                            min: 0,
                            max: 100,
                            display: false,
                        },
                    },

                    // show legend, but make it not clickable
                    legend: {
                        display: false,
                        position: 'bottom',
                        onClick: (e) => e.stopPropagation(),
                    },
                },
            });

            // if (this.averageData.length) {
            //     spiderChart.data.datasets.push({
            //         label: 'Durchschnitt',
            //         backgroundColor: 'rgb(84,141,255, 0.2)',
            //         borderColor: 'rgb(84,141,255, 0.2)',
            //         data: this.averageData,
            //     });

            //     spiderChart.update();
            // }
        },
    },
};
</script>
