var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        _vm.headingPosition < 0
            ? 'fixed-top floatNavigation'
            : 'listNavigation'
    ]},_vm._l((_vm.areas),function(area,i){return _c('div',{key:area.id,staticClass:"area"},[_c('p',{staticClass:"areaLink"},[_vm._v("\n            "+_vm._s(area.name)+"\n        ")]),_vm._v(" "),(i === _vm.activeArea)?_c('div',{attrs:{"id":"navigationStageList"}},_vm._l((area.stages),function(stage,ind){return _c('p',{key:stage.id,class:[
                    _vm.stageIndex === ind
                        ? 'active'
                        : '',
                    'stage mb-0'
                ],attrs:{"id":("navigationStage-" + (stage.id)),"href":("#stage-" + (stage.id))}},[_vm._v("\n                "+_vm._s(stage.name)+"\n            ")])}),0):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }