<template>
    <div class="mb-3">
        <p
            class="text-center m-0 funding-info-wrapper"
        >
            <span
                v-if="additional[0].answer &&
                    additional[1].answer &&
                    additional[2].answer"
                class="funding-info-text"
            >
                Sie sind <span class="funding-qualified">förderfähig.</span>
            </span>
            <span
                v-else
                class="funding-info-text"
            >
                Sie sind leider <span class="not-funding-qualified">nicht förderfähig.</span> <!-- eslint-disable-line max-len -->
            </span>
        </p>
        <p
            v-if="additional[0].answer &&
                additional[1].answer &&
                additional[2].answer"
            class="text-center mt-4 funding-explain"
        >
            Sie erfüllen alle Voraussetzungen für eine go-digital-Förderung. Gerne informieren wir Sie in einem persönlichen Gespräch über die Möglichkeiten. <!-- eslint-disable-line max-len -->
        </p>
        <p
            v-else
            class="text-center mt-4 funding-explain"
        >
            Leider erfüllen Sie nicht alle Voraussetzungen für eine go-digital-Förderung. Gerne beraten wir Sie jedoch auch zu anderen möglichen Förder- oder Umsetzungsmaßnahmen. <!-- eslint-disable-line max-len -->
        </p>
    </div>
</template>

<script>
export default {
    computed: {
        additional() {
            return this.$store.state.additional;
        },
    },
};
</script>
<style lang="scss" scoped>
.funding-info-wrapper {
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    font-size: 25px;

    .funding-info-text {
        background: #efefef;
        padding: 0 10px;

        .funding-qualified {
            color: green;
        }

        .not-funding-qualified {
            color: red;
        }
    }
}

.funding-explain {
    font-size: 15px;
}
</style>
