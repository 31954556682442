<template>
    <div
        :class="[
            headingPosition < 0
                ? 'fixed-top floatChart'
                : '',
        ]"
    >
        <div class="chartContainer px-3">
            <canvas id="progressChart" />

            <div class="progression">
                {{ progress }}%
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        areas: {
            required: true,
            type: Array,
            default() {
                return [];
            },
        },

        result: {
            required: true,
            type: Number,
            default() {
                return 0;
            },
        },
    },

    data() {
        return {
            ctx: null,
            chart: null,
            questionCount: 0,
            headingPosition: document
                .getElementById('mainHeading')
                .getBoundingClientRect()
                .bottom,
            progress: 0,
        };
    },

    watch: {
        result: function(newValue) {
            this.updateChart(newValue);
        },
    },

    mounted() {
        this.areas.forEach((area) => {
            area.stages.forEach((stage) => {
                stage.tools.forEach((tool) => {
                    this.questionCount += tool.questions.length;
                });
            });
        });

        this.ctx = document
            .getElementById('progressChart')
            .getContext('2d');

        this.renderChart();

        window.addEventListener(
            'scroll',
            this.updateScrollPosition,
        );
    },

    methods: {
        /**
         * Draw initial chart without values.
         */
        renderChart() {
            this.chart = new Chart(this.ctx, {
                type: 'doughnut',
                data: {
                    labels: ['Fortschritt', 'Verbleibend'],
                    datasets: [
                        {
                            backgroundColor: ['#153f93', '#efefef'],
                            data: [0, 100],
                        },
                    ],
                },
                options: {
                    aspectRatio: 1,
                    legend: {display: false},
                },
            });

            // Fill chart with real values.
            this.updateChart();
        },

        /**
         * Update chart.
         *
         * @param {Number} answerdQuestions - Number to update.
         */
        updateChart(answerdQuestions = 0) {
            // Calculate percentile
            const percent = Math.floor((answerdQuestions / this.questionCount) * 100);

            // Set data attribute and update.
            this.chart.data.datasets[0].data = [percent, 100 - percent];
            this.chart.update();

            // Update displayed percentage.
            this.progress = percent;
        },

        /**
         * Update scroll position variable.
         */
        updateScrollPosition() {
            this.headingPosition = document
                .getElementById('mainHeading')
                .getBoundingClientRect()
                .bottom;
        },
    },
};
</script>

<style lang="scss" scoped>
.chartContainer {
    position: relative;
    z-index: 5;

    & .progression {
        top: 45%;
        left: 0;
        width: 100%;
        text-align: center;
        position: absolute;
        font-size: 20px;
        opacity: 0.5;
        color: #595959;
    }
}

#progressChart {
    z-index: 10;
}

.floatChart {
    padding-top: 60px;
    padding-right: 48px;
    right: 0;
    left: auto;
    max-width: inherit;
}
</style>
