<template>
    <div>
        <h2>
            {{ areaName }}
        </h2>
        <div class="row mb-3">
            <div class="col-7">
                <span>
                    {{
                        `${count} Fragen | ${overall}${
                            (isSpecific) ? '' : '%'
                        } mit Ja beantwortet`
                    }}
                </span>
            </div>
            <div class="col-5">
                <div class="input-group">
                    <input
                        v-model="mail"
                        class="form-control input-element"
                        type="text"
                        placeholder="E-Mail"
                    >
                    <div class="input-group-append">
                        <button
                            class="btn filled"
                            type="button"
                            @click="() => $emit('search', mail)"
                        >
                            <i class="far fa-search" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="background">
            <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Frage</th>
                        <th>Ja</th>
                        <th>Nein</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(question, index) in questions"
                        :key="`question-${index}`"
                    >
                        <td>
                            {{ `${index + 1}.` }}
                        </td>
                        <td>
                            {{ question.text }}
                        </td>
                        <td
                            :class="(question.yes > question.no) ?
                                'active fw-600' : ' fw-600'"
                        >
                            {{ formatDisplay(question.yes) }}
                        </td>
                        <td
                            :class="(question.yes < question.no) ?
                                'active  fw-600' : ' fw-600'"
                        >
                            {{ formatDisplay(question.no) }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <button
                v-if="isSpecific && mail !== '' && count"
                class="btn filled"
                @click="() => removeMail()"
            >
                E-Mail Adresse löschen
            </button>
        </div>
    </div>
</template>

<script>
import {getRoute} from '../../helpers';
export default {
    props: {
        area: {
            required: true,
            type: Object,
            default() {
                return {};
            },
        },
        isSpecific: {
            required: true,
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    data() {
        return {
            mail: '',
        };
    },

    computed: {
        questions() {
            return Object.values(this.area)[0];
        },
        count() {
            if (!this.questions) {
                return 0;
            }
            return this.questions.length;
        },
        overall() {
            if (!this.questions) {
                return 0;
            }
            if (this.isSpecific) {
                return this.questions.reduce((value, points) => {
                    if (points.yes !== '') {
                        return value + 1;
                    }
                    return value;
                }, 0);
            }

            return (this.questions.reduce((value, points) => {
                return value + points.yes;
            }, 0) / this.count).toFixed(2);
        },
        areaName() {
            return Object.keys(this.area)[0];
        },
    },

    methods: {
        /**
         * Wrapper for the anser td
         * @param {Number|String} value The value to display
         * @return {String} The td content
         */
        formatDisplay(value) {
            if (this.isSpecific) {
                return (value !== '') ? value : '-';
            }
            return `${value}%`;
        },

        /**
         * Function to remove the mai from the db
         */
        removeMail() {
            getRoute('ajax.statistics.remvoeMail').then((route) => {
                axios.post(route, {mail: this.mail}).then((response) => {
                    alert(
                        `Die E-Mail wurde erfolgreich
                        aus der Datenbank entfernt!`,
                    );
                }).catch((error) => {
                    alert('Fehler beim löschen der E-Mail!');
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    h2 {
        margin-top: 60px;
    }

    .background {
        padding: 35px;
        background-color: lighten(lightgray, 13);
    }

    .input-element {
        border-radius: 0px;
        height: 50px;
        padding: 12px 24px;
        outline: none;
        box-shadow: none;

        &:focus {
            z-index: 0;
        }
    }

    .filled {
        border-radius: 0;
        font-weight: 600;
        font-size: 16px !important;
        background: #153f93;
        color: white;
        height: 50px;
        font-family: Arial;
        border: 2px solid #153f93;
        font-size: inherit;
        padding: 0 2em;
        cursor: pointer;
        outline: none;

        &:hover {
            background: #f8fafc;
            color: #153f93;
        }
    }

    span {
        color: gray;
    }

    table {
        thead {
            th {
                border-top: none;
            }
        }

        tbody {
            :nth-child(even) {
                background-color: lighten(rgba(211, 211, 211, 0.2), 5);
            }
            tr {
                td {
                    background-color: transparent !important;

                    &.fw-600 {
                        font-weight: 600;
                    }
                    &.active {
                        color: #153f93;
                    }
                }
                &:hover {
                    background-color: lighten(
                        rgba(7, 24, 39, 0.2), 20
                    ) !important;
                }
            }
        }
    }
</style>
