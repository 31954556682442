var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"area-headline text-center"},[_vm._v("\n        "+_vm._s(_vm.area.name)+"\n    ")]),_vm._v(" "),_c('Stage',{staticClass:"survey",attrs:{"stage":_vm.area.stages[_vm.stageIndex],"stage-result-data":_vm.areaResultData[
                _vm.area.stages[_vm.stageIndex].name.toLowerCase()
            ],"first-area-and-stage":_vm.area.id === 1 && _vm.stageIndex === 0},on:{"answered":function($event){return _vm.addAreaToResult($event, _vm.area.name)},"nextStage":function($event){return _vm.nextStage()},"previousStage":function($event){return _vm.previousStage()},"removeQuestion":function($event){return _vm.$store.commit(
            'removeQuestion',
            {
                questionID: $event.questionID,
                toolName: $event.toolName,
                stageName: $event.stageName,
                areaName: _vm.area.name
            }
        );}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }