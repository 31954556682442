<!--
    Display area name, hold the area specific stage
    Main component for the survey questions
-->
<template>
    <div>
        <div class="area-headline text-center">
            {{ area.name }}
        </div>

        <Stage
            class="survey"
            :stage="area.stages[stageIndex]"
            :stage-result-data="
                areaResultData[
                    area.stages[stageIndex].name.toLowerCase()
                ]
            "
            :first-area-and-stage="area.id === 1 && stageIndex === 0"
            @answered="addAreaToResult($event, area.name)"
            @nextStage="nextStage()"
            @previousStage="previousStage()"
            @removeQuestion="$store.commit(
                'removeQuestion',
                {
                    questionID: $event.questionID,
                    toolName: $event.toolName,
                    stageName: $event.stageName,
                    areaName: area.name
                }
            );"
        />
    </div>
</template>

<script>
export default {
    props: {
        area: {
            required: true,
            type: Object,
            default() {
                return {};
            },
        },

        areaResultData: {
            required: false,
            type: Object,
            default() {
                return {};
            },
        },
    },

    computed: {
        stageIndex() {
            return this.$store.state.stageIndex;
        },
    },

    methods: {
        /**
         * Add area name to result object.
         * Result object is now complete and can be evaluated.
         * setResult mutation is called to evaluate object.
         *
         * @param {Object} resultData - Question tool and stage data.
         * @param {String} areaName - Name of question area.
         */
        addAreaToResult(resultData, areaName) {
            const resultDataWithArea = {
                areaName: areaName,
                toolName: resultData.toolName,
                stageName: resultData.stageName,
                question: resultData.question,
            };

            this.$store.commit('setResult', resultDataWithArea);
        },

        /**
         * Incerement store index to load next stage.
         */
        nextStage() {
            if (this.stageIndex === this.area.stages.length - 1) {
                this.$emit('nextArea');
                this.$store.commit('setStageIndex', 0);
            } else {
                this.$store.commit('setStageIndex', this.stageIndex + 1);
            }
        },

        /**
         * Decrement store index to load previous stage.
         */
        previousStage() {
            if (this.stageIndex > 0) {
                this.$store.commit('setStageIndex', this.stageIndex - 1);
            } else {
                this.$emit('previousArea');
                this.$store.commit('setStageIndex', 0);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.survey {
    margin-bottom: 60px;
    padding: 30px;
    background-color: #efefef;
}
.area-headline {
    font-size: 36px;
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 30px;
    padding: 20px;
    background-color: #efefef;
}
</style>
