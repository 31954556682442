<!--
    Display a single question from the Survey
-->
<template>
    <div
        :id="`question-${question.id}`"
        class="mb-3"
    >
        <p
            :class="[
                disabled ? 'disabled' : '', 'text-center question-text m-0'
            ]"
        >
            {{ question.text }}
        </p>

        <b-row>
            <ul class="checkBoxList">
                <b-row
                    :class="[
                        disabled ? 'disabled' : ''
                    ]"
                >
                    <b-col>
                        <li class="checkBoxList leftBox">
                            <input
                                :id="`answer_yes${question.id}`"
                                class="quizquestion btn-block mt-1"
                                type="radio"
                                :name="`answer${question.id}`"
                                :disabled="disabled"
                                :checked="questionResultData.value == 1"
                                @click="doAnswer(1)"
                            >
                            <label
                                class="checkboxlabel"
                                :for="`answer_yes${question.id}`"
                            >
                                <p class="checkboxText">&nbsp; &nbsp;Ja</p>
                            </label>
                        </li>
                    </b-col>
                    <b-col>
                        <li class="checkBoxList">
                            <input
                                :id="`answer_no${question.id}`"
                                class="quizquestion btn-block mt-1"
                                type="radio"
                                :name="`answer${question.id}`"
                                :disabled="disabled"
                                :checked="questionResultData.value == 0"
                                @click="doAnswer(0)"
                            >

                            <label
                                class="checkboxlabel"
                                :for="`answer_no${question.id}`"
                            >
                                <p class="checkboxText">&nbsp; &nbsp;Nein</p>
                            </label>
                        </li>
                    </b-col>
                </b-row>
            </ul>
        </b-row>
    </div>
</template>

<script>
export default {

    props: {
        question: {
            required: true,
            type: Object,
            default() {
                return {};
            },
        },
        disabled: {
            required: true,
            type: Boolean,
            default() {
                return false;
            },
        },

        questionResultData: {
            required: false,
            type: Object,
            default() {
                return {};
            },
        },
    },

    data() {
        return {
            alreadyAnswered: false,
        };
    },

    watch: {
        disabled: function(oldValue, newValue) {
            if (newValue === false) {
                this.doAnswer(0);
            } else {
                this.$emit('removeQuestion', {questionID: this.question.id});
            }
        },
    },

    mounted() {
        if (this.disabled) {
            this.doAnswer(0);
        }
    },

    methods: {
        /**
         * Set state and emit the answer to parent component
         * @param {Boolean} answer question answer
         */
        doAnswer(answer) {
            this.$emit('answered', {
                id: this.question.id,
                type: this.question.type,
                value: answer,
            });

            if (!this.alreadyAnswered) {
                this.$store.commit('setAnsweredQuestions');
                this.alreadyAnswered = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.question-text {
    font-size: 22px;
    font-weight: 300;
    transition: 0.2s all;
}
.disabled {
    opacity: 0.5;
    pointer-events: none;
}

@media screen and (min-width:0\0) {
    .leftBox {
        left: -120px;
    }
}
</style>
