<!--
    Shows the previous and next buttons
-->
<template>
    <b-row class="mt-5 justify-content-between">
        <b-col
            cols="4"
            class="border-top border-dark pl-0"
        >
            <div
                v-if="showPrevious"
                class="float-left text-left survey-nav-container"
                @click="$emit('previous')"
            >
                <i class="fal fa-chevron-left survey-nav-icon mr-4" />

                <span class="survey-nav d-table-cell left">
                    {{ labelPrevious }}
                </span>
            </div>
        </b-col>

        <b-col
            cols="4"
            class="border-top border-dark pr-0"
        >
            <div
                :class="[
                    enableNext ? '': 'disabled',
                    'float-right text-right survey-nav-container'
                ]"
                @click="$emit('next')"
            >
                <span class="survey-nav d-table-cell right">
                    {{ labelNext }}
                </span>

                <i class="fal fa-chevron-right survey-nav-icon ml-4" />
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: {
        showPrevious: {
            required: true,
            type: Boolean,
            default() {
                return true;
            },
        },
        enableNext: {
            required: true,
            type: Boolean,
            default() {
                return false;
            },
        },
        labelPrevious: {
            required: false,
            type: String,
            default() {
                return 'Zurück';
            },
        },
        labelNext: {
            required: true,
            type: String,
            default() {
                return 'Weiter';
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.description-text {
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}

.survey-nav-container {
    cursor: pointer;
    padding-top:10px;
    padding-right: 0;
    padding-left: 0;
    display: table;
    transition: 0.2s all;

    &:hover > .survey-nav-icon {
        transform: scaleY(0.6);
        opacity: 1;
        color: #153f93;
    }

    &:hover > .left {
        transform: translate(-15px);
        color: #153f93;
    }

    &:hover > .right {
        transform: translate(15px);
        color: #153f93;
    }
}

.survey-nav {
    transition: 0.2s all;
    font-size: 28px;
    vertical-align: middle;
    width: inherit;
}

.survey-nav-icon {
    font-size: 58px;
    color: #343a40;
    opacity: 0.1;
    transition: 0.4s all;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>
