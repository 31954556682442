<!--
    Shows the final result
    Contains charts and form
-->
<template>
    <div>
        <Heading :title="'Ihr Ergebnis'" />

        <b-row class="justify-content-center">
            <div class="col-md-4 mb-5 tachoChartContainer">
                <div id="revenueChart" />
            </div>
            <!-- <div class="col-md-3 fuelChartContainer">
                <div id="pointsChart" />
            </div>
            <div class="col-md-4 tachoChartContainer">
                <div id="riskChart" />
            </div> -->
        </b-row>

        <b-row>
            <b-col v-if="!isMobile">
                <Radar
                    :graph-labels="areaNamesAndPoints.names"
                    :graph-percentages="graphPercentages"
                />
            </b-col>
            <b-col v-else>
                <div
                    v-for="(percent, index) in graphPercentages"
                    :key="index"
                >
                    <p class="text my-3 text-center">
                        <b>
                            {{ areaNamesAndPoints.names[index] }}:
                        </b> {{ percent }}%
                    </p>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p class="thank-you-text">
                    Vielen Dank für Ihre Teilnahme.
                    Wir haben den Stand der Digitalisierung Ihres Unternehmens
                    in ausgewählten Bereichen bewertet und grafisch aufbereitet.
                    Gerne besprechen wir die Ergebnisse im Detail und geben
                    Ihnen ergänzende Hinweise.
                </p>
            </b-col>
        </b-row>
        <b-row style="margin-top: 70px">
            <b-col>
                <FundingInfo />
            </b-col>
        </b-row>
        <b-row style="margin-top: 70px">
            <b-col>
                <AnalysisForm
                    :points="points"
                    :area-percentages="graphPercentages"
                    :refference="uID"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import uuid from 'uuid';

export default {
    props: {
        areas: {
            required: true,
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            average: [],
            areaInfo: {},
            graphPercentages: [],
            uID: uuid.v4(),
        };
    },

    computed: {
        result() {
            return this.$store.state.result;
        },

        points() {
            return this.$store.state.points;
        },

        areaNamesAndPoints() {
            return this.$store.state.areaNamesAndPoints;
        },

        isMobile() {
            return this.$store.state.mobile;
        },

        additional() {
            return this.$store.state.additional;
        },
    },

    mounted() {
        this.$store.commit('getCalculatedPoints');

        this.areaNamesAndPoints.points.forEach((point, index) => {
            this.graphPercentages = [
                ...this.graphPercentages,
                this.toPercent({
                    value: point,
                    max: this.areaNamesAndPoints.pointsMax[index],
                }),
            ];
        });

        // this.getAverage();
        this.submitResults();

        new Graph('revenueChart', 'revenue', this.isMobile).show(
            this.points.revenue.value,
            this.points.revenue.max,
        );

        // new Graph('pointsChart', 'points', this.isMobile).show(
        //     this.points.points.value,
        //     this.points.points.max,
        // );

        // new Graph('riskChart', 'risk', this.isMobile).show(
        //     this.points.risk.max - this.points.risk.value,
        //     this.points.risk.max,
        // );

        this.areas.map((area) => {
            Vue.set(
                this.areaInfo,
                [area.name],
                {percent: 10},
            );
        });
    },

    methods: {
        /**
         * Submit the result from the survey
         */
        submitResults() {
            axios.post('ajax/storeStatistics', {
                type_id: this.$store.state.type,
                position_id: this.$store.state.position,
                answerd_questions: this.result,
                result: this.points,
                key: this.$store.state.key,
                uID: this.uID,
                additional: this.$store.state.additional,
            });
        },

        /**
         * Get the average statisitics for given type and position
         */
        getAverage() {
            axios.post('ajax/getAverage', {
                type_id: this.$store.state.type,
                position_id: this.$store.state.position,
            }).then((response) => {
                this.average = response.data;
            });
        },

        /**
         * Change given Integer into Percentage
         * @param {Object} value Max and value.
         * @return {Float} Rounded percentage value
         */
        toPercent(value) {
            return Math.round(100 / value.max * value.value);
        },
    },
};
</script>

<style lang="scss" scoped>
.tachoChartContainer {
    height: 250px;
}

.thank-you-text {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
}

.fuelChartContainer {
    padding-top: 18px;
    height: 240px;
}

.largeNotMobile {
    height: 300px!important;
    width: 300px!important;
}

.smallNotMobile {
    height: 225px!important;
    width: 225px!important;
}
</style>
