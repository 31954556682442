<!--
    Shows additional questions about the company and
-->
<template>
    <div>
        <Heading title="Ihre Förderfähigkeit" />

        <p class="go-digital-text">
            <!-- eslint-disable-next-line max-len -->
            Sie wollen mit der go-digital Förderung des BMWI bei der Umsetzung Ihres Digitalisierungsprojektes bis zu 16.500 EUR Fördersumme erhalten? Beantworten Sie kurz 3 Fragen und kommen Sie der Förderung einen Schritt näher!
        </p>

        <Segment
            v-for="(question, index) in questions"
            :key="question.id"
            :index="index"
            :question="question"
            @answered="storeAnswer($event)"
        />

        <PrevAndNextButton
            :show-previous="false"
            :enable-next="checkAllSet()"
            :label-next="'Weiter'"
            @next="startQuestions()"
        />
    </div>
</template>

<script>
import {additionalQuestions} from './questions';

export default {
    data() {
        return {
            answers: {
                0: null,
                1: null,
                2: null,
            },
            questions: [],
        };
    },

    created() {
        const questions = additionalQuestions.map((question) => {
            if (
                typeof question.requiredType === 'undefined' ||
                question.requiredType === this.$store.state.type
            ) {
                return question;
            }
        });

        this.questions = questions.filter((el) => {
            return el != null;
        });
    },

    methods: {
        /**
         * Set Answers
         * @param {Object} data additional question answers
         */
        storeAnswer(data) {
            this.answers[data.index] = {
                question: {
                    text: this.questions[data.index].text,
                    type: this.questions[data.index].type,
                },
                answer: data.value,
            };
        },

        /**
         * Commit ansers to storage
         * Start with the survey questions
         */
        startQuestions() {
            this.$store.commit('setAdditional', this.answers);
            this.$emit('startQuestions');
        },

        /**
         * Check if all questions answerd
         * @return {Boolean} If all questions answered returns true
         */
        checkAllSet() {
            for (const key in this.answers) {
                if (Object.prototype.hasOwnProperty.call(
                    this.answers,
                    key,
                )) {
                    if (this.answers[key] === null) {
                        return false;
                    }
                }
            }
            return true;
        },
    },
};
</script>
<style>
.go-digital-text {
    text-align: center;
    font-size: 22px;
}
</style>
