<!--
    Display the stage name and hold the stage specific tools
-->
<template>
    <div :id="`stage-${stage.id}`">
        <Heading :title="stage.name" />

        <h4 class="tool-headline mb-5 text-center">
            {{ stage.tools[toolIndex].name }}
        </h4>

        <Tool
            :id="`tool-${stage.tools[toolIndex].id}`"
            :tool="stage.tools[toolIndex]"
            :tool-result-data="
                stageResultData[
                    stage.tools[toolIndex].name.toLowerCase()
                ]
            "
            @answered="addStageToResult($event, stage.name)"
            @removeQuestion="$emit(
                'removeQuestion',
                {
                    questionID: $event.questionID,
                    toolName: $event.toolName,
                    stageName: stage.name
                }
            );"
        />

        <PrevAndNextButton
            :show-previous="toolIndex !== 0"
            :enable-next="
                stageResultLength === stage
                    .tools[toolIndex]
                    .questions
                    .length
            "
            :label-previous="'Zurück'"
            :label-next="'Weiter'"
            @previous="previousTool()"
            @next="nextTool()"
        />
    </div>
</template>

<script>
export default {
    props: {
        stage: {
            required: true,
            type: Object,
            default() {
                return {
                    id: '',
                    name: '',
                    tools: [],
                };
            },
        },

        firstAreaAndStage: {
            required: true,
            type: Boolean,
            default() {
                return true;
            },
        },

        stageResultData: {
            required: false,
            type: Object,
            default() {
                return {};
            },
        },
    },

    data() {
        return {
            toolIndex: 0,
        };
    },

    computed: {
        stageResultLength() {
            return this.stageResultData[
                this.stage.tools[this.toolIndex].name.toLowerCase()
            ] ?
                Object.keys(this.stageResultData[
                    this.stage.tools[this.toolIndex].name.toLowerCase()
                ]).length :
                0;
        },
    },

    methods: {
        /**
         * Check if tool is last in arry. Emit event if true
         * else incement index.
         */
        nextTool() {
            if (this.toolIndex === this.stage.tools.length - 1) {
                this.$emit('nextStage');
                this.toolIndex = 0;
            } else {
                this.toolIndex += 1;
            }
        },

        /**
         * Decrement toolIndex if possible.
         */
        previousTool() {
            if (this.toolIndex > 0) {
                this.toolIndex -= 1;
            } else {
                this.$emit('previousStage');
                this.toolIndex = 0;
            }
        },

        /**
         * Add stage id to question result.
         *
         * @param {Object} resultData - Question and tool data.
         * @param {String} stageName - Stage name of answered question.
         */
        addStageToResult(resultData, stageName) {
            const resultDataWithStage = {
                stageName: stageName,
                toolName: resultData.toolName,
                question: resultData.question,
            };

            this.$emit('answered', resultDataWithStage);
        },
    },
};
</script>

<style lang="scss" scoped>
.tool-headline {
    font-size: 28px;
    font-weight: 600;
}
</style>
