<template>
    <div>
        <Heading :title="'Ihre Betriebsart'" />

        <b-row class="pb-5">
            <b-col
                v-for="(type, index) in types"
                :key="'type-' + index"
                md="6"
            >
                <ul class="checkBoxList">
                    <li class="checkBoxList text-left">
                        <input
                            :id="`type-${type.id}`"
                            class="quizquestion btn-block mt-1"
                            type="radio"
                            name="type"
                            :checked="selectedType === type.id"
                            @click="selectType(type.id)"
                        >

                        <label
                            class="checkboxlabel ml-2"
                            :for="`type-${type.id}`"
                        >
                            <p class="checkboxText">
                                &nbsp; &nbsp;{{ type.name }}
                            </p>
                        </label>
                    </li>
                </ul>

                <label
                    class="description-text text-left pt-3"
                    :for="`type-${type.id}`"
                />
            </b-col>
        </b-row>

        <Heading
            :title="'Ihre Ambitionen'"
            :style="[show ? {} : {display: 'none'}]"
        />

        <b-row
            class="pb-5"
            :style="[show ? {} : {display: 'none'}]"
        >
            <b-col
                v-for="(position, index) in positions"
                :key="'position-' + index"
                md="4"
            >
                <ul class="checkBoxList">
                    <li class="checkBoxList text-left">
                        <input
                            :id="`position-${position.id}`"
                            class="quizquestion btn-block mt-1"
                            type="radio"
                            name="position"
                            :checked="selectedPosition === position.id"
                            @click="selectPosition(position.id)"
                        >

                        <label
                            class="checkboxlabel ml-2"
                            :for="`position-${position.id}`"
                        >
                            <p class="checkboxText">
                                &nbsp; &nbsp;{{ position.name }}
                            </p>
                        </label>
                    </li>
                </ul>

                <label
                    class="description-text text-left pt-3"
                    :for="`position-${position.id}`"
                >
                    {{ position.description }}
                </label>
            </b-col>
        </b-row>

        <PrevAndNextButton
            :show-previous="false"
            :enable-next="!!positionAndTypeSelected"
            :label-next="'Weiter'"
            @next="nextStep()"
        />
    </div>
</template>

<script>
export default {
    props: {
        show: {
            required: true,
            type: String,
            default() {
                return '';
            },
        },
    },

    data() {
        return {
            types: [],
            positions: [],
            selectedType: false,
            selectedPosition: false,
            defaultPosition: 4,
            hidePosition: true,
        };
    },

    computed: {
        positionAndTypeSelected() {
            return this.selectedType && this.selectedPosition;
        },
    },

    mounted() {
        this.getPositionAndTypes();

        if (this.show !== '1') {
            this.selectPosition(this.defaultPosition);
        }
    },

    methods: {
        /**
         * Set the selected type id
         * @param {Integer} id Type id
         */
        selectType(id) {
            this.$store.commit('setType', id);
            this.selectedType = id;
        },

        /**
         * Set the selected position id
         * @param {Integer} id Position id
         */
        selectPosition(id) {
            this.$store.commit('setPosition', id);
            this.selectedPosition = id;
        },

        /**
         * Emit event which will lead towards the next quiz step.
         */
        nextStep() {
            if (this.selectedType && this.selectedPosition) {
                this.$emit('positionAndTypeSelected');
            }
        },

        /**
         * Get the types
         */
        getPositionAndTypes() {
            axios.post('ajax/getTypes').then((response) => {
                this.types = response.data;
            });

            axios.post('ajax/getPositions').then((response) => {
                this.positions = response.data.slice(0, 3);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.description-text {
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}
</style>
